import React from 'react'


export const PlusIconDAS = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_22_866)">
            <path d="M10 4.16675V15.8334" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.16699 10H15.8337" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_22_866">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>)
}

export const FolderIconDAS = (): JSX.Element => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.957 16.2525L28.737 15.95C28.352 15.4825 27.8983 15.1112 27.3758 14.8362C26.6745 14.4375 25.877 14.2312 25.052 14.2312H7.93329C7.10829 14.2312 6.32454 14.4375 5.60954 14.8362C5.07329 15.125 4.59204 15.5237 4.19329 16.0187C3.40954 17.0225 3.03829 18.26 3.16204 19.4975L3.67079 25.9187C3.84954 27.8575 4.08329 30.25 8.44204 30.25H24.557C28.9158 30.25 29.1358 27.8575 29.3283 25.905L29.837 19.5112C29.9608 18.3562 29.6583 17.2012 28.957 16.2525ZM19.7858 23.8425H13.1995C12.6633 23.8425 12.237 23.4024 12.237 22.8799C12.237 22.3574 12.6633 21.9174 13.1995 21.9174H19.7858C20.322 21.9174 20.7483 22.3574 20.7483 22.8799C20.7483 23.4162 20.322 23.8425 19.7858 23.8425Z" fill="#787878"/>
            <path opacity="0.4" d="M4.64746 15.5513C4.94996 15.2763 5.25246 15.0288 5.60996 14.8363C6.31121 14.4375 7.10871 14.2313 7.93371 14.2313H25.0662C25.8912 14.2313 26.675 14.4375 27.39 14.8363C27.7475 15.0288 28.0637 15.2763 28.3525 15.565V14.8363V13.5025C28.3525 8.59375 26.8537 7.095 21.945 7.095H18.6725C18.0675 7.095 18.0537 7.08125 17.6962 6.61375L16.0462 4.4C15.2625 3.3825 14.6437 2.75 12.6775 2.75H11.055C6.14621 2.75 4.64746 4.24875 4.64746 9.1575V14.85V15.5513Z" fill="#787878"/>
        </svg>)
}

export const EmptyFolderIcon = (): JSX.Element => {
    return(
        <svg height="112px" width="112px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 226.51 226.51" xmlSpace="preserve" fill="#949296" stroke="#949296">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> 
                <g> 
                    <path style={{fill: "#949296"}} d="M186.268,9.011H38.929c0,0-3.04,0-6.799,0c-3.753,0-8.577,4.536-10.764,10.128L3.009,65.958 c-2.187,5.591-3.47,14.974-2.856,20.951l12.287,119.774c0.609,5.978,5.983,10.818,11.988,10.818h177.672 c6.005,0,11.379-4.846,11.988-10.818l12.287-119.774c0.609-5.978-0.87-15.273-3.312-20.755l-21.414-47.238 C199.158,13.444,192.272,9.011,186.268,9.011z M148.162,148.615H78.362c-6.005,0-10.878-4.873-10.878-10.878v-24.476 c0-6.005,4.873-10.878,10.878-10.878h69.799c6.005,0,10.878,4.873,10.878,10.878v24.476 C159.04,143.742,154.166,148.615,148.162,148.615z M28.834,68.514l6.88-20.201c1.936-5.684,8.376-10.296,14.386-10.296h122.896 c6.005,0,12.863,4.444,15.311,9.932l9.361,20.935c2.448,5.488-0.435,9.932-6.445,9.932H36.209 C30.199,78.816,26.898,74.204,28.834,68.514z">
                    </path> 
                </g> 
            </g>
        </svg>)
}

export const EditIconDas = (): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_19_659)">
                <path d="M12 15.0001L20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 5L19 8" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.99992 7.07007C7.24571 7.32001 5.65156 8.22592 4.53904 9.60505C3.42651 10.9842 2.87841 12.7339 3.00528 14.5013C3.13216 16.2687 3.92457 17.9222 5.22269 19.1283C6.5208 20.3343 8.228 21.0033 9.99992 21.0001C11.6833 21.0003 13.3104 20.3938 14.583 19.2918C15.8556 18.1899 16.6884 16.6662 16.9289 15.0001" stroke="#4F46E5" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_19_659">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>)
}

export const DeleteIcoDas = (): JSX.Element => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1916 5.01216C18.6487 4.85883 17.1058 4.74383 15.5533 4.65758V4.648L15.3425 3.40216C15.1987 2.5205 14.9879 1.198 12.7454 1.198H10.2345C8.0016 1.198 7.79077 2.463 7.63744 3.39258L7.43619 4.61925C6.54494 4.67675 5.65369 4.73425 4.76244 4.8205L2.80744 5.01216C2.40494 5.0505 2.11744 5.40508 2.15577 5.798C2.1941 6.19091 2.5391 6.47841 2.9416 6.44008L4.8966 6.24841C9.9183 5.75008 14.9783 5.94175 20.0575 6.44966C20.0862 6.44966 20.1054 6.44966 20.1341 6.44966C20.4983 6.44966 20.8145 6.17175 20.8529 5.798C20.8816 5.40508 20.5941 5.0505 20.1916 5.01216Z" fill="#EC6242"/>
            <path opacity="0.3991" d="M18.4294 7.80075C18.1994 7.56117 17.8831 7.427 17.5573 7.427H5.44399C5.11816 7.427 4.79232 7.56117 4.57191 7.80075C4.35149 8.04034 4.22691 8.36617 4.24607 8.70159L4.84024 18.5341C4.94565 19.9908 5.07982 21.8116 8.42441 21.8116H14.5769C17.9214 21.8116 18.0557 20.0003 18.161 18.5341L18.7552 8.71117C18.7744 8.36617 18.6498 8.04034 18.4294 7.80075Z" fill="#EC6242"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.18164 16.2917C9.18164 15.8948 9.50343 15.573 9.90041 15.573H13.0917C13.4886 15.573 13.8104 15.8948 13.8104 16.2917C13.8104 16.6887 13.4886 17.0105 13.0917 17.0105H9.90041C9.50343 17.0105 9.18164 16.6887 9.18164 16.2917Z" fill="#EC6242"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38477 12.4583C8.38477 12.0613 8.70656 11.7395 9.10352 11.7395H13.8952C14.2921 11.7395 14.6139 12.0613 14.6139 12.4583C14.6139 12.8552 14.2921 13.177 13.8952 13.177H9.10352C8.70656 13.177 8.38477 12.8552 8.38477 12.4583Z" fill="#EC6242"/>
        </svg>)
}

export const PrincipalIconDas = (): JSX.Element => {
   return <svg
   xmlns="http://www.w3.org/2000/svg"
   data-name="Capa 3"
   viewBox="0 0 401.35 449.4"
 >
   <path
     fill="#eaeaea"
     stroke="#ddd"
     strokeMiterlimit="10"
     d="M51.58 357.34c-15.88 1.77-26.76-7.35-31.23-19.69-3.02-8.34-3.11-32.14 14.9-39.21l1.95-128.45c-35.66-14.37-16.5-68.93 26.08-55.36l105.39-64.94c-1.4-10.66 1.41-19.28 9.05-25.55 13.46-11.55 27.52-10.69 42.05 0 8.46 8.89 11.13 17.95 8.78 27.15l104.06 64.4c3.24-2.8 7.44-4.02 12.24-4.26 15.68 1.56 25.84 9.49 30.34 23.95 2.95 17.36-3.61 27.61-15.97 33.53l.53 127.21 6.39 6.39c10.68 11.03 11.86 23.57 5.85 37.26-9.37 14.63-23.58 19.02-42.76 12.95l-100.07 62.81c.44 7.76-.35 12.6-4.97 19.87-4.61 7.27-14.96 13.01-27.32 13.48-14.33-2.37-23.76-10.43-25.9-26.97-1.76-12.84 1.88-23.22 16.16-28.74v-86.58c.2-3.65 1.42-6.89 4.07-9.58l61.03-34.78v-56.62s-62.48-33.98-65.1-42.03c-1.16-3.56-.67-38.49-.51-45.24.16-6.75 1.15-45.44.51-45.43-3.1-.58-5.3-2.06-6.4-4.61L81.9 131.46c1.28 5.89 1.2 11.99 0 18.27l94.21 55.87c25.24-6.35 38.37 4.06 43.11 26.1 1.19 14.12-4.79 23.8-17.55 29.27-9.22 3.08-18.44 4.21-27.69 1.6L80.3 320.85c.53 19.07-13.31 34.69-28.74 36.46zm9.4-183.81V296.8c3.32-.4 6.02.42 8.52 2.35l90.49-55.71c-1.89-5.85-1.89-11.18 0-15.97l-91.2-57.84-7.81 3.9zM211.44 72.75v79.49l61.39 38.68c3.22 2.36 4.36 6.19 4.61 10.65v71.32c-1.85 3.2-5.02 6.14-9.23 8.87l-55.36 34.42-1.42 75.58c3.85.18 6.34 1.46 7.81 3.55l95.45-61.74c-3.27-18.51 3.94-30.15 19.16-36.55V169.63c-11.71 0-23.95-18.98-18.1-38.32l-96.87-58.55h-7.45z"
   ></path>
 </svg>
}