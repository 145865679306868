export const ROUTES_DASHBOARD = {
    HOME : "/home",
    DASHBOARD: "/dashboard",
    DATAIMPORT: "/dataImport",
    DATAMANAGEMENT: "/data-management",
    COLLECTIONS: "/collections",
    UPGRADE: "/upgrade",
    SUPPORT: "/support",
    ABOUT: "/about",
    CAMPAIGNS: "/campaigns"
}