export const initialValue = {
    token: '',
    setToken:() => { },
    setDeleteToken:() => { },
    setUserData:() => { },
    setLogin: () => { },
    webDataNetsRequest: () => { return fetch('') },
    setSignUp: () => { },
    userSignUpData: () => { },
    setUserSignUpData:() => { },
    openToast: false,
    messageToast: '',
    handleToast: () => {},
    setOpenToast: () => {},
    t: () => '',
    i18n: null,
    openProfile: false,
    setOpenProfile:() => {},
}