import React from 'react'
import { CSVUpload } from './components/CSVUpload'

export const DataImport = () => {
    return (
        <div >
            <CSVUpload/>
        </div>
    )
}
