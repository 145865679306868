import React from 'react'
import { ModalComponent } from './ModalComponent'

export const NewCampaignComponent=()=> {
  return (
    <div>
        <ModalComponent/>
    </div>
  )
}
